import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '29, 40, 55',
		'primary-dark': '17, 24, 33',
		'accent': '246, 177, 181',
		'accent-plus': '0, 0, 0',
	},
});
